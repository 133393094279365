<script setup lang="ts">
/* Pinia stores */

/**
 * The auth Pinia store.
 */
const auth = useAuthStore();

/**
 * The terminal Pinia store.
 */
const terminalStore = useTerminalStore();

/* Component variables */

/**
 * The switch redeem location dialog.
 */
const dialog: Ref<HTMLDialogElement | null> = ref(null);

/* Computed variables */

/**
 * The user's redeem locations.
 */
const redeemLocations: ComputedRef<RedeemLocation[]> = computed(
    (): RedeemLocation[] => (auth.user as AuthUser).redeem_locations,
);

/* Component methods */

/**
 * Show the switch redeem location dialog.
 */
const showDialog = (): void => {
    (dialog.value as HTMLDialogElement).showModal();
};

/* Component methods */

/**
 * Switch the redeem location.
 */
const switchRedeemLocation = async (redeemLocation: RedeemLocation): Promise<void> => {
    // Set the redeem location on the terminal Pinia store
    terminalStore.redeemLocation = redeemLocation;

    // Hide the dialog
    (dialog.value as HTMLDialogElement).close();
};

/* Exposes */

defineExpose({
    // Expose the show dialog method to parent components
    showDialog,
});
</script>

<template>
    <dialog ref="dialog" class="dialog-small">
        <div class="dialog-container">
            <header>
                <h1 class="dialog-heading">
                    Redeem locations
                </h1>
            </header>

            <div class="terminal-button-group">
                <button type="button" v-for="redeemLocation in redeemLocations"
                        :key="redeemLocation.id"
                        class="button button-terminal button-outline-primary"
                        @click="switchRedeemLocation(redeemLocation)">
                    {{ redeemLocation.name }}
                </button>
            </div>

            <footer class="dialog-footer">
                <button type="button" class="button button-tertiary-secondary"
                        @click="(dialog as HTMLDialogElement).close()">
                    Cancel
                </button>
            </footer>
        </div>
    </dialog>
</template>
