<script setup lang="ts">
import DialogSwitchRedeemLocation from '~/components/dialog/DialogSwitchRedeemLocation.vue';
import DialogSwitchAccount from '~/components/dialog/DialogSwitchAccount.vue';
import { homePath } from '~/utils/routes';

/* Pinia stores */

/**
 * The auth Pinia store.
 */
const authPinia = useAuthStore();

/**
 * The terminal Pinia store.
 */
const terminalStore = useTerminalStore();

/* Component variables */

/**
 * The sidebar element.
 */
const sidebar: Ref<HTMLDivElement | null> = ref(null);

/**
 * The show sidebar button element.
 */
const showSidebarButton: Ref<HTMLButtonElement | null> = ref(null);

/**
 * Is the sidebar open.
 */
const sidebarOpen: Ref<boolean> = ref(false);

/**
 * The switch redeem location dialog.
 */
const switchRedeemLocationDialog: Ref<typeof DialogSwitchRedeemLocation | null> = ref(null);

/**
 * The switch account dialog.
 */
const switchAccountDialog: Ref<typeof DialogSwitchAccount | null> = ref(null);

/* Computed variables */

/**
 * The current partner.
 */
const partner: ComputedRef<Partner> = computed((): Partner => (useCommonStore().partner));

/**
 * The currently authenticated user.
 */
const user: ComputedRef<AuthUser | null> = computed((): AuthUser | null => authPinia.user);

/**
 * Determines if the user has multiple redeem locations.
 *
 * @type {ComputedRef<boolean>}
 * @description A computed reference that returns a boolean value indicating whether the user has
 * multiple redeem locations.
 *
 * @returns {boolean} Whether the user has multiple redeem locations.
 */
const hasMultipleRedeemLocations: ComputedRef<boolean> = computed(
    (): boolean => {
        // If the user is set (not set when signing out)
        if (user.value) {
            // Check if the user has multiple redeem locations
            return (user.value as AuthUser).redeem_locations.length > 1;
        }

        // Otherwise, false
        return false;
    },
);

/**
 * The currently set redeem location.
 */
const redeemLocation: ComputedRef<RedeemLocation | null> = computed(
    (): RedeemLocation | null => {
        if (terminalStore.redeemLocation) {
            return terminalStore.redeemLocation as RedeemLocation;
        }

        return null;
    },
);

/* Component methods */

/**
 * Listen for a click event and close the sidebar on trigger.
 */
const listenForClick = (event: Event): void => {
    // If the sidebar is set (needed to handle events triggering before the button is mounted)
    if (sidebar.value) {
        const target = event.target as Element;
    }

    // If the open sidebar button is set (needed to handle events triggering before the button is
    // mounted)
    if (showSidebarButton.value) {
        const target = event.target as Element;

        // If the event is inside the sidebar
        if (showSidebarButton.value.contains(target)) {
            // Ignore the event
            return;
        }
    }

    // If the sidebar is open
    if (sidebar.value) {
        // Close the menu
        hideSidebar();
    }
};

/**
 * Show the sidebar.
 */
const showSidebar = (): void => {
    sidebarOpen.value = true;

    // Add the event listener to listen for clicks outside the sidebar
    document.addEventListener('click', listenForClick);
};

/**
 * Close the sidebar.
 */
const hideSidebar = (): void => {
    sidebarOpen.value = false;

    // Remove the event listener to listen for clicks outside the sidebar
    document.removeEventListener('click', listenForClick);
};

/**
 * Open the switch redeem location dialog.
 */
const openSwitchRedeemLocationDialog = (): void => {
    (switchRedeemLocationDialog.value as typeof DialogSwitchRedeemLocation).showDialog();
};

/**
 * Open the switch account dialog.
 */
const openSwitchAccountDialog = (): void => {
    (switchAccountDialog.value as typeof DialogSwitchAccount).showDialog();
};
</script>

<template>
    <template v-if="user">
        <div id="navbar">
            <button type="button" id="terminal-sidebar-button" class="button button-no-style"
                    ref="showSidebarButton" @click="showSidebar">
                <SvgMenu/>
            </button>

            <button v-if="hasMultipleRedeemLocations" type="button" id="navbar-redeem-location-selector"
                    class="button button-no-style" @click="openSwitchRedeemLocationDialog">
                <img id="navbar-logo" :src="partner.logo_url" :alt="partner.name + ' logo'"/>

                <span class="redeem-location-name-container">
                    <span class="redeem-location-name overflow-ellipsis">
                        {{ redeemLocation ? (redeemLocation as RedeemLocation).name : '' }}
                    </span>

                    <SvgChevronDown/>
                </span>
            </button>

            <div v-else id="navbar-redeem-location-selector">
                <img id="navbar-logo" :src="partner.logo_url" :alt="partner.name + ' logo'"/>

                <span class="redeem-location-name-container">
                    {{ redeemLocation ? (redeemLocation as RedeemLocation).name : '' }}
                </span>
            </div>
        </div>

        <div v-if="sidebarOpen" id="terminal-sidebar" ref="sidebar">
            <div id="terminal-sidebar-links">
                <button class="button button-no-style terminal-sidebar-close-button"
                        @click="hideSidebar">
                    <SvgClose/>

                    Close
                </button>

                <hr>

                <NuxtLink v-if="authPinia.role === 'admin'" :to="homePath()" class="terminal-sidebar-link">
                    Back to dashboard
                </NuxtLink>

                <button v-if="(user as AuthUser).email" type="button"
                        class="button button-no-style terminal-sidebar-link"
                        @click="openSwitchAccountDialog">
                    Switch account
                </button>

                <NuxtLink to="/terminal/auth/settings" class="terminal-sidebar-link">
                    User settings
                </NuxtLink>

                <button type="button"
                        class="button button-no-style terminal-sidebar-link terminal-sidebar-button-danger"
                        @click="authPinia.logout">
                    Log out
                </button>
            </div>

            <div id="user-container">
                <SvgUser/>

                <span id="username">
                    {{ (user as AuthUser).email ?? (user as AuthUser).username }}
                </span>
            </div>
        </div>

        <DialogSwitchRedeemLocation v-if="hasMultipleRedeemLocations" ref="switchRedeemLocationDialog"/>
        <DialogSwitchAccount v-if="user" ref="switchAccountDialog" redirect-url="/terminal/redeem"/>
    </template>
</template>

<style scoped>
    #navbar {
        display: flex;
        align-items: center;
        height: 3.75rem;
        padding: 0.375rem 0.5rem;
        background: #FFF;
        gap: 1rem;
    }

    #terminal-sidebar-button {
        height: 2.5rem;
        padding: 0 0.75rem;
        color: var(--grey-400)
    }

    #navbar-redeem-location-selector {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        padding-top: 0.5rem;
    }

    #navbar-logo {
        height: 1.25rem;
    }

    .redeem-location-name-container {
        display: flex;
        align-items: center;
        padding-right: 0.75rem;
        color: var(--grey-500);
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.5rem;
        gap: 0.5rem;
    }

    .redeem-location-name-container > svg {
        height: 0.6rem
    }

    .redeem-location-name {
        text-align: left;
    }

    #terminal-sidebar {
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-width: 20rem;
        height: 100%;
        padding: 2rem 3.75rem 2.5rem;
        background: #FFF;
    }

    #terminal-sidebar-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;
    }

    .terminal-sidebar-close-button {
        display: flex;
        height: 2.75rem;
        color: var(--grey-500);
        font-size: 0.875rem;
        font-weight: 600;
        gap: 0.625rem;
    }

    .terminal-sidebar-link {
        display: flex;
        align-items: center;
        height: 2.75rem;
        color: var(--grey-500);
        font-size: 0.875rem;
        font-weight: 500;
    }

    .terminal-sidebar-link:hover {
        color: var(--grey-400);
    }

    .terminal-sidebar-button-danger {
        color: var(--danger-600);
    }

    .terminal-sidebar-button-danger:hover {
        color: var(--danger-500);
    }

    #user-container {
        display: flex;
        align-items: center;
        max-width: 100%;
        height: 2.5rem;
        color: var(--grey-500);
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.0125rem;
        line-height: 1.5rem;
        gap: 1.5rem;
    }

    /* 768px */
    @media screen and (width >= 48em) {
        #navbar {
            height: 5rem;
            padding: 0.625rem 2rem;
            gap: 2rem;
        }

        #navbar-logo {
            height: 2rem;
        }
    }
</style>
